  
  .analysisContainer {
    max-width: 900px;
    border-radius: 20px;
    flex-grow: 1;
    margin-left: 40px;
    height: auto;
    align-self: start;  
    padding: 8px;
    background: linear-gradient(to top, rgba(0, 128, 0, 0.575), rgba(255, 82, 82, 0.662));
    font-family: "Helvetica Neue";
    border: 4px solid #878787;

  }
  
  .analysisOptions {
    display: flex;
    height: auto;
    padding-top:6px;
    padding-bottom:10px

  }

  .optionButton {
    flex-grow: 1;
    padding: 10px;
    margin-inline: 5px;
    width: 100%;
    color: white;
    font-weight: bold;
  }