body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
    background: linear-gradient(to bottom, rgba(16, 124, 16, 0.711), rgba(255, 82, 82, 0.672));
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.disclosure{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    font-size: 20px;
    color: white;
    font-weight: 600;
    text-align: center;
}
.ava {
    margin-top: 10px;
    margin-left:20px;
    display: flex;
    flex-direction: row;
    color: black;
}

.avaConfig {
    margin-left:20px;
    border-radius: 20px;
    max-width: 400px;
    padding: 10px;
    flex-direction: column;
    align-self: start;  

    height:auto;
    flex-grow: 1;
    background: linear-gradient(to top, rgba(0, 128, 0, 0.575), rgba(255, 82, 82, 0.662));
    font-family: "Helvetica Neue";
    border: 4px solid #878787; /* Border style and color */

}

.inputGroup {
    border-radius: 10px;
    padding: 12px;
    margin: 10px;
    color: white;
    background: linear-gradient(to right, rgba(0, 128, 0, 0.636), rgba(255, 82, 82, 0.57));
    border: 1px solid #878787; /* Border style and color */
}

.inputDescription {
    font-size: 18px;
    font-weight: bold;
}

.input {
    display: flex;
    flex-direction: row;
    padding:10px

}


.inputBox {
    max-width: 150px;
    max-height: 27px;
    font-size:18px;
    margin:4px
}

.runButton {
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 5px;
}


  
  .analysisContainer {
    max-width: 900px;
    border-radius: 20px;
    flex-grow: 1;
    margin-left: 40px;
    height: auto;
    align-self: start;  
    padding: 8px;
    background: linear-gradient(to top, rgba(0, 128, 0, 0.575), rgba(255, 82, 82, 0.662));
    font-family: "Helvetica Neue";
    border: 4px solid #878787;

  }
  
  .analysisOptions {
    display: flex;
    height: auto;
    padding-top:6px;
    padding-bottom:10px

  }

  .optionButton {
    flex-grow: 1;
    padding: 10px;
    margin-inline: 5px;
    width: 100%;
    color: white;
    font-weight: bold;
  }
.mapKey-container {
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    margin-bottom: 24px;

    color: white;
    background: linear-gradient(to right, rgba(0, 128, 0, 0.636), rgba(255, 82, 82, 0.57));
    border: 1px solid #878787;
    font-weight: bold;
    position: absolute;

}

.mapKey {
    font-size: 18px;
}

.mapKey{
    font-size: 16px;
    font-weight: bold;
    display: inline-flex;
    flex-direction: row;
}
.analyticsTable {
    border-radius: 30px;
    background-color: #ffffff;
}

.tableContainer {
    border-radius: 10px;
    padding: 12px;
    padding-bottom: 0px;
    margin: 10px;
    color: white;
    background: linear-gradient(to right, rgba(0, 128, 0, 0.636), rgba(255, 82, 82, 0.57));
    border: 1px solid #878787;
}

.chartContainer {
    /* display: flex; */
    justify-content: center;
  }

.noGraphText {
    font-size: 18px;
    font-weight: bold;
    margin: 10px;
    color: white
}
