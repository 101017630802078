.mapKey-container {
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    margin-bottom: 24px;

    color: white;
    background: linear-gradient(to right, rgba(0, 128, 0, 0.636), rgba(255, 82, 82, 0.57));
    border: 1px solid #878787;
    font-weight: bold;
    position: absolute;

}

.mapKey {
    font-size: 18px;
}

.mapKey{
    font-size: 16px;
    font-weight: bold;
    display: inline-flex;
    flex-direction: row;
}