.analyticsTable {
    border-radius: 30px;
    background-color: #ffffff;
}

.tableContainer {
    border-radius: 10px;
    padding: 12px;
    padding-bottom: 0px;
    margin: 10px;
    color: white;
    background: linear-gradient(to right, rgba(0, 128, 0, 0.636), rgba(255, 82, 82, 0.57));
    border: 1px solid #878787;
}

.chartContainer {
    /* display: flex; */
    justify-content: center;
  }

.noGraphText {
    font-size: 18px;
    font-weight: bold;
    margin: 10px;
    color: white
}