.avaConfig {
    margin-left:20px;
    border-radius: 20px;
    max-width: 400px;
    padding: 10px;
    flex-direction: column;
    align-self: start;  

    height:auto;
    flex-grow: 1;
    background: linear-gradient(to top, rgba(0, 128, 0, 0.575), rgba(255, 82, 82, 0.662));
    font-family: "Helvetica Neue";
    border: 4px solid #878787; /* Border style and color */

}

.inputGroup {
    border-radius: 10px;
    padding: 12px;
    margin: 10px;
    color: white;
    background: linear-gradient(to right, rgba(0, 128, 0, 0.636), rgba(255, 82, 82, 0.57));
    border: 1px solid #878787; /* Border style and color */
}

.inputDescription {
    font-size: 18px;
    font-weight: bold;
}

.input {
    display: flex;
    flex-direction: row;
    padding:10px

}


.inputBox {
    max-width: 150px;
    max-height: 27px;
    font-size:18px;
    margin:4px
}

.runButton {
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 5px;
}

