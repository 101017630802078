.app {
    background: linear-gradient(to bottom, rgba(16, 124, 16, 0.711), rgba(255, 82, 82, 0.672));
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.disclosure{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    font-size: 20px;
    color: white;
    font-weight: 600;
    text-align: center;
}